import { ButtonLink } from "../../../General/Buttons/ButtonLink/ButtonLink";

export const KnowledgeObjectDetailsCollapsed = ({ knowledgeObject, onClick }) => {
  return (
    <div className="d-flex flex-column">
      <div className="col-12">
        <h5 className={"fw-bold"}>{knowledgeObject.title}</h5>
      </div>
      <div className="d-flex flex-wrap align-items-baseline">
        {knowledgeObject.topics.length > 0 && (
          <ButtonLink 
            text={`${knowledgeObject.topics.length} topics`} 
            onClick={onClick}/>
        )}
        <h5>,</h5>
        {knowledgeObject.subtopics.length > 0 && (
          <ButtonLink 
            text={`${knowledgeObject.subtopics.length} subtopics`} 
            onClick={onClick}/>
        )}
        <h5>,</h5>
        {knowledgeObject.keywords.length > 0 && (
          <ButtonLink 
            text={`${knowledgeObject.keywords.length} keywords`} 
            onClick={onClick}/>
        )}
        <h5>,</h5>
        {knowledgeObject.creators.length > 0 && (
          <ButtonLink 
            text={`${knowledgeObject.creators.length} creators`} 
            onClick={onClick}/>
        )}
        <h5>,</h5>
        {knowledgeObject.creators.length > 0 && (
          <ButtonLink 
            text={`${knowledgeObject.creators.length} creators`} 
            onClick={onClick}/>
        )}
      </div>
    </div>
  );
};