import { faCheckCircle, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

// TODO: Remove preview logic from here, because now there is a FilePreview component
export const FilePreview = ({ file, status, trashIcon, onRemove }) => {
  return (
    <>
      <h3>This is file preview</h3>
      <div className="d-flex flex-row justify-content-between mb-4">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row align-items-center">
            <FontAwesomeIcon icon={faFilePdf} style={{color: "var(--light-blue-color)" }} size="2xl"/>
            <div className="d-flex flex-column mx-3">
              <strong>{file.filename}</strong>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-row align-items-center">
            <p className="m-2">{status}</p>
            {status === 'Uploaded' &&
              <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: "var(--green-color)"}}/>

            }
            {trashIcon && (
              <FontAwesomeIcon
                  className='ms-2'
                icon={faTrashAlt}
                style={{ color: "var(--red-color)" }}
                onClick={onRemove}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};