import {OffCanvasHelp} from "../../../General/OffCanvasHelp/OffCanvasHelp";
import {faPlus, faUser, faAt} from "@fortawesome/free-solid-svg-icons";
import {ButtonPrimary} from "../../../General/Buttons/ButtonPrimary/ButtonPrimary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {TagButton} from "../../../General/Buttons/TagButton/TagButton";
import './Creator.css';
import {Form} from "react-bootstrap";
import {RequiredIndicator} from "../RequiredIndicator";

export const Creator = ({
    title,
    name,
    help_id,
    description,
    creators,
    handleStateCreators,
    isRequired=false,
    validation
})=>{

    const [creator, setCreator] = useState({name: '', email: ''});
    const [creatorsList, setCreatorsList] = useState(creators??[]);
    const [error, setError] = useState(null);

    const handleCreatorFormChange = (event) => {
        setCreator({...creator, [event.target.name]: event.target.value});
    }

    const add_creator = () => {

        let email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(isRequired && !(creator.name.length > 0 && email_pattern.test(creator.email))){
            setError('Please type in the name and email of the creator');
            return;
        }

        setCreatorsList([...creatorsList, {name: creator.name, email: creator.email}]);
        setCreator({name: '', email: ''});
        setError(null);

    };

    const rm_creator = (event) => {
        event.preventDefault();
        const value = event.target.getAttribute('value');
        setCreatorsList(
            creatorsList.filter(
                (creator,index) => index !== parseInt(value)
            )
        );
    };

    useEffect(() => {
        handleStateCreators(creatorsList);
    }, [creatorsList]);


    return (
        <>
            <p className='fw-bolder fs-5' id={`multitext-${name}`}>
                {isRequired && <RequiredIndicator/>}
                {title}
                <OffCanvasHelp help_id={help_id} title={title}></OffCanvasHelp>
            </p>
            <p>{description}</p>
            <div className="d-flex justify-content-start w-100 gap-2 align-items-center">
                <div className="input-group  d-flex align-items-center">
                    <div className="input-group-prepend" style={{marginLeft:'5px', position:'absolute',zIndex:1000}}>
                        <FontAwesomeIcon className="mx-1" icon={faUser}/>
                    </div>
                    <input style={{textIndent:'20px'}} type="text" name='name' value={creator.name} onChange={handleCreatorFormChange} className="form-control" placeholder="name of the creator" aria-label="Username" aria-describedby="basic-addon1"/>
                </div>
                <div className="input-group d-flex align-items-center">
                    <div className="input-group-prepend" style={{marginLeft:'5px', position:'absolute',zIndex:1000}}>
                        <FontAwesomeIcon className="mx-1" icon={faAt}/>
                    </div>
                    <input style={{textIndent:'20px'}} type="email" name='email' value={creator.email} onChange={handleCreatorFormChange} className="form-control" placeholder="email of the creator" aria-label="Username" aria-describedby="basic-addon1"/>
                </div>
                <ButtonPrimary
                    type="button"
                    text='Αdd creator'
                    variant='primary'
                    onClick={add_creator}
                    icon={faPlus}
                    size='14'
                    padding='6'
                    fontWeight='500' />
            </div>

            {
                error!=null &&
                <p className="mt-2" style={{ color: 'var(--red-color)'}}>
                    {error}
                </p>
            }

            {error==null && validation && validation.errorMessage && (
                <p className="mt-2" style={{ color: 'var(--red-color)'}}>
                    Please type in the {title}
                </p>
            )}

            <div className="d-flex flex-wrap gap-1 mt-3 pt-3 border-top">
                {creators.map((creator, index) => {
                    return (
                        <TagButton
                            text={`${creator.name} (${creator.email})`}
                            onClick={rm_creator}
                            value={index}/>
                    );
                })}
            </div>
        </>
    );
};