import { useState } from 'react';
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import {InfoIcon} from "../../../General/InfoIcon/InfoIcon";
import Offcanvas from 'react-bootstrap/Offcanvas';
import {helpText} from "../../../../services/FormService";
import {OffCanvasHelp} from "../../../General/OffCanvasHelp/OffCanvasHelp";
import {RequiredIndicator} from "../RequiredIndicator";

export const RadioGroup = ({ 
  title, 
  description, 
  options, 
  name, 
  value, 
  handleSelectedOption,
  help_id,
  validation,
    required = false,
    ...props
}) => {


  const onChange = (event, option) => {
    event.preventDefault();
    handleSelectedOption(option);
  };

  return (
    <>
      <p className='fw-bolder fs-5' id={`radio-${name}`}>
          {required && <RequiredIndicator/>}
          {title}

          <OffCanvasHelp help_id={help_id} title={title}></OffCanvasHelp>

      </p>
      <p>{description}</p>
      <div className="d-flex flex-wrap">
        {options.map((option) => (
          <Form.Check
            label={option.label ?? option}
            name={name}
            type='radio'
            key={'radio-'+name+'-'+option.id ?? option}
            id={`${name}-${option.id ?? option}`}
            value={option.label ?? option}
            checked={option.label ? option.label === value.label : option === value}
            onChange={(event)=>{onChange(event, option)}}
            style={{ flex: "50%" }}/>
        ))}
      </div>
      {validation && validation.errorMessage && (
        <p className="mt-2" style={{ color: 'var(--red-color)'}}>
          Please Choose {title}
        </p>
      )}
    </>
  );
};