import {DEFAULTS} from "../defaults/defaults";

const baseURL = process.env.API_ENDPOINT??DEFAULTS.API_URL;
const AUTHORIZATION_KEY = process.env.CREDENTIALS??DEFAULTS.AUTHORIZATION;

export const uploadFile = (file, onProgress, user_id) => {
  const url = `${baseURL}knowledge_object/upload_knowledge_object/${user_id}`;
  return new Promise((response, rejection) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization',AUTHORIZATION_KEY??'');
    xhr.onload = () => {
      const result = JSON.parse(xhr.responseText);
      response(result);
    };

    xhr.onerror = (event) => rejection(event);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded/event.total)*100;
        onProgress(Math.round(percentage));
      }
    }
    const formData = new FormData();
    formData.append('ufile', file);
    xhr.send(formData);
  });
};

export const fileTypesRequest = () => {
  return `${baseURL}logical_layer/upload_form_data_model/categories`
};

export const topicsRequest = () => {
  return `${baseURL}logical_layer/upload_form_data_model/topics`;
};

export const fieldOptionsListRequest = ()=>{
  return `${baseURL}logical_layer/upload_form_data_model/option_lists`
};

export const fileTypeSubtopicsRequest = (typeID) => {
  return `${baseURL}logical_layer/upload_form_data_model/categories?fileTypes=${typeID}`;
}

export const subtopicsRequest = (topicIds) => {
  const queryParams = topicIds.join("&topics=");
  return topicIds.length > 0 ? baseURL + 'logical_layer/upload_form_data_model/subtopics?topics=' + queryParams : [];
};

export const fileTypeCategoriesRequest = (typeID) => {
  return typeID!=undefined ? baseURL + 'logical_layer/upload_form_data_model/categories?fileTypes=' + typeID : [];
};

export const languageRequest = () => {
  return baseURL + 'logical_layer/upload_form_data_model/languages';
};

export const licenseRequest = () => {
  return baseURL + 'logical_layer/upload_form_data_model/licence';
};

export const purposeRequest = () => {
  return baseURL + 'logical_layer/upload_form_data_model/purpose';
};

export const locationsRequest = () => {
  return baseURL + 'logical_layer/upload_form_data_model/location';
};

export const helpText = async (help_id) => {
  const response = await fetch(`${baseURL}logical_layer/fetch/help?field=${help_id}`,{
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      'Authorization':AUTHORIZATION_KEY??""
    },
  });

  return (response && response.ok)
      ? response.json()
      : 'N/A';
};

export const submitKORequest = async ({ user_id,ko_upload_collection_id, project, knowledgeObjects }) => {
  const url = baseURL + 'knowledge_object/save_ko_collection_metadata';

  /** transform knowledge object file to only keep uid, name nd locale */
  const kos = knowledgeObjects.map(ko => ({
    ...ko, // spread to create a shallow copy of ko
    files: ko.files.map(fileWrapper => ({ // create a new array for files
      _id: fileWrapper.file.uid,
      name: fileWrapper.file.name,
      locale: fileWrapper.file.locale
    }))
  }));


  const data = { user_id:user_id, ko_upload_collection_id:ko_upload_collection_id, project, kos };

  const response = await fetch(url, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      'Authorization': AUTHORIZATION_KEY??""
    },
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify({ form_data: data })
  });

  if (response.ok) {
    const data = await response.json();
    console.log('Data saved: ', data);
    return {
      success:true,
      data:data
    };
  } else {
    console.error("Error in response:", response);
    return {
      success:false,
      response: response
    };
  }
};