import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import {OffCanvasHelp} from "../../../General/OffCanvasHelp/OffCanvasHelp";
import {RequiredIndicator} from "../RequiredIndicator";

export const CheckBoxGroup = ({ 
  title, 
  name,
  description, 
  groupName,
  help_id,
  values, 
  options, 
  handleSelectedOptions, 
  validation,
    required = false,
}) => {

  const onChange = (event) => {
    event.preventDefault();
    const selectedValue = options.filter(option => event.target.value == option.id)[0];
    if (values && values.filter(option => option.id === selectedValue.id).length > 0) {
      handleSelectedOptions(values.filter(option => option.id != event.target.value))
    } else {
      handleSelectedOptions([...values, selectedValue]);
    }
  };

  return (
    <>
      <p className='fw-bolder fs-5' id={`checkbox-${name}`}>
        {required && <RequiredIndicator/>}
        {title}
        <OffCanvasHelp help_id={help_id} title={title}></OffCanvasHelp>
      </p>
      <p>{description}</p>
      <Form.Group>
        <div className="d-flex flex-wrap">
          {options.map((option) => (
            <Form.Check
              label={option.label}
              name={groupName}
              type='checkbox'
              key={`checkbox-${groupName}-${option.id}`}
              id={`${name}-${option.id ?? option}`}
              value={option.id}
              checked={values && values.filter((item) => item.id === option.id).length > 0}
              onChange={onChange}
              style={{ flex: "50%" }}/>
          ))}
        </div>
      </Form.Group>
      {validation && validation.errorMessage && (
        <p className="mt-2" style={{ color: 'var(--red-color)'}}>
          Please select {title}
        </p>
      )}
    </>
  );
};