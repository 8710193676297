import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import './TagButton.css'

export const TagButton = ({ text, onClick, size, padding, value }) => {
  return (
    <div className="d-flex flex-row align-items-center tag-container m-1" 
      style={{ fontSize: `${size}px`, padding: `${padding}px`, backgroundColor: 'var(--light-gray-color)'}}>
      {text}
      <button className='tag-button' onClick={onClick} value={value}>
        <FontAwesomeIcon 
          className="xmark-icon"
          icon={faCircleXmark} 
          style={{ color: 'black' }} 
          type="solid" 
          value={value}/>
      </button>
    </div>
  );
};