import {useCallback, useState, useRef, useEffect} from "react";
import {useDropzone} from "react-dropzone";
import {FileUploadWithProgress} from "./FileUpload/FileUploadWithProgress";
import {FileUploadError} from "./FileUpload/FileUploadError";
import './FileInput.css';
import {RequiredIndicator} from "../RequiredIndicator";

export const FileInput = ({
    userId,
    title,
    description,
    handleFile,
    handleFileDeletion,
    handleFileLocaleSelection,
    updateFileOperation,
    validation,
    name,
    languages,
    files,
    required = false
}) => {

    const inputRef = useRef(null);
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        const mappedAccepted = acceptedFiles.map(file => ({file, errors: []}));
        const mappedRejected = rejectedFiles.map((r) => ({...r}));
        handleFile([
            ...mappedAccepted,
            ...mappedRejected
        ]);
    }, []);

    const openFileSelector = (e) => {
        e.preventDefault();
        e.stopPropagation();
        inputRef.current.click();
    };

    const {getRootProps, getInputProps} = useDropzone({
        onDrop, maxFiles: 100,
        maxSize: 150 * 1024 * 1024 * 1024,
        accept: {
            // Document types
            'text/plain': ['.txt', '.csv', '.md', '.htm', '.html', '.dvi', '.dbk', '.xml', '.epub', '.fb2', '.fbz', '.docx', '.docm', '.doc', '.odt', '.fodt', '.oxps', '.xps', '.pdf', '.ps'],

            // Slideshow presentation types
            'application/pdf': ['.pdf', '.potm', '.potx', '.ppt', '.pptx'],

            // Dataset types
            'application/octet-stream': ['.shp', '.shx', '.dbf', '.prj', '.sbx', '.sbn', '.tif', '.tfw', '.dwg', '.gml', '.mdb', '.mif', '.kml', '.ai', '.dxf', '.svg'],

            // Video types
            'video/*': ['.drc', '.mkv', '.mk3d', '.mka', '.mks', '.mp4', '.m4a', '.m4p', '.m4b', '.m4r', '.m4v', '.webm', '.ogv', '.ogg'],

            // Audio types
            'audio/*': ['.m4a', '.caf', '.flac', '.mpc', '.mp+', '.mpp', '.mp3', '.bit', '.ogg', '.oga', '.ogx', '.ogm', '.spx', '.opus', '.wv'],

            // Image types
            'image/*': ['.png', '.apng', '.flif', '.gbr', '.gif', '.jp2', '.j2k', '.jpf', '.jpg', '.jpx', '.jpm', '.mj2', '.mng', '.exr', '.pdf', '.png', '.svg', '.svgz', '.tiff', '.webp', '.xpm'],
        },
    });

    // TODO: Need also to delete the file from backend
    const onDelete = (file,e) => {
        e.preventDefault();
        // setFiles((oldValues) => oldValues.filter((file, i) => i !== index));
        handleFileDeletion(file);
    };

    const handleLocalChange = (file, index, e) => {
        e.preventDefault();
        const newFiles = [...files];
        newFiles[index].file.locale = e.target.value;
        // setFiles(newFiles);
        handleFileLocaleSelection(file, e.target.value);
    };

    return (
        <>
            <p className='fw-bolder fs-5' id={`file-input-${name}`}>
                {required && <RequiredIndicator/>}
                {title}
            </p>
            <p>{description}</p>
            <section>
                <div className="upload-file-container">
                    <div {...getRootProps({className: "dropzone"})}>
                        <input {...getInputProps({ref: inputRef})} />
                        <div className="d-inline-flex mb-2 mt-4">
                            <p>Drag & Drop or <a href="javascript:void(0)" onClick={openFileSelector}>Choose a file</a> to upload.</p>
                        </div>
                        <div className="file-types">
                            <p className="file-types-header">SUPPORTED FILETYPES</p>
                            <p>Documents, Slideshow presentation, Dataset, Video, Audio, Image</p>
                        </div>
                    </div>
                </div>
                {
                    files && files.map((fileWrapper, index) => (
                            fileWrapper.errors.length ? (
                                <FileUploadError
                                    key={index}
                                    file={fileWrapper.file}
                                    onDelete={onDelete}
                                    errors={fileWrapper.errors}/>
                            ) : (
                                <FileUploadWithProgress
                                    userId={userId}
                                    key={index}
                                    file_index={index}
                                    file={fileWrapper.file}
                                    onDelete={onDelete}
                                    onOpenFileSelector={openFileSelector}
                                    handleLocalChange={handleLocalChange}
                                    updateFileOperation={updateFileOperation}
                                    languages={languages}
                                    handleFile={handleFile}/>
                            )
                        )
                    )}
            </section>
            {validation && validation.errorMessage && (
                <p className="mt-2" style={{color: 'var(--red-color)'}}>
                    Please upload a file.
                </p>
            )}
        </>
    )
};