import { useState } from "react";

/**
 * Custom hook for handling Form's steps
 * @param Number steps
 */
export const useMultiStepForm = ({ steps }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const next = () => {
    setCurrentStepIndex(i => {
      if (i >= steps - 1) return i;
      return i + 1;
    });
  }; 

  const back = () => {
    setCurrentStepIndex(i => {
      if (i <= 0) return i;
      return i - 1;
    });
  };

  const goTo = (index) => {
    setCurrentStepIndex(index);
  };

  return {
    currentStepIndex,
    //step: steps[currentStepIndex],
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps - 1,
    goTo,
    next,
    back
  };
};
