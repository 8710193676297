import {InfoIcon} from "../InfoIcon/InfoIcon";
import { useState } from 'react';
import {helpText} from "../../../services/FormService";
import Offcanvas from "react-bootstrap/Offcanvas";

export const OffCanvasHelp = ({
    help_id,
    title,
    ...props
}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const loadme = async ()=>{
        document.querySelector('.offcanvas-body').innerHTML = await helpText(help_id);
    };

    return (
        <div className="ms-2 d-inline-flex">
            <a href="javascript:void(0)" onClick={handleShow} className={`${help_id?'d-inline-block':'d-none'} text-black`}>
                <InfoIcon onClick={handleShow}></InfoIcon>
            </a>

            <Offcanvas onEntered={loadme} show={show} onHide={handleClose} {...props} >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <i>Loading...</i>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};