import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ButtonPrimary.css'

export const ButtonPrimary = ({ text, onClick, variant, value, icon, size, padding, fontWeight, type }) => {
  return (
    <div className='flex-column py-2'>
      <button className={`button-${variant} d-flex flex-row align-items-center`} 
        type={type} 
        onClick={onClick} 
        value={value} 
        style={{ 
          fontSize: `${size}px`, 
          whiteSpace: 'nowrap', 
          padding: `${padding}px`, 
          fontWeight 
        }}>
        {text}
        {icon ? (
          <FontAwesomeIcon className="mx-1" icon={icon}/>
        ) : (
          <></>
        )}
      </button>
    </div>
  );
};