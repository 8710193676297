import './FormWrapper.css'

export const FormWrapper = ({ title, children }) => {
  return (
    <>
      <h1 className='form-wrapper-title text-start'>{title}</h1>
      <div>{children}</div>
    </>
  );
};
