import {useEffect, useState} from 'react';
import {FormWrapper} from '../../../../components/Form/Containers/FormWrapper/FormWrapper';
import {useValidation} from '../../../../hooks/useValidation';
import {ControlSection} from '../../../../components/Form/ControlSection/ControlSection';
import {Form} from 'react-bootstrap';
import './ProjectInfo.css'
import {ProjectList} from "../../../../components/Form/ProjectList/ProjectList";

export const ProjectInfo = ({
    selectedProject,
    projects,
    setSelectedAvailableProject,
    handleNextStep
}) => {

    const [formErrors, validateForm, validateOnChange] = useValidation({selectedProject: selectedProject});
    const [isSubmited, setIsSubmited] = useState(false);

    const handleSubmit = (event) => {
        if (validateForm({formData: {selectedProject:selectedProject}, isSubmited})) {
            event.preventDefault();
            setIsSubmited(false);
            handleNextStep();
        } else {
            event.preventDefault();
            setIsSubmited(true)
            event.stopPropagation();
        }
    };

    const selectProject = (project) => {
        setSelectedAvailableProject(project);
    };


    return (
        <>
            <FormWrapper title='Step 1: Select Project'>
                {
                    projects.length > 0 ? (
                        <ProjectList
                            projects={projects}
                            handleSelectProject={selectProject}
                            selectedProject={selectedProject}
                        />
                    ) : (
                        <div className="alert alert-info" role="alert">
                            <p>Currently, there are no projects available for you to upload files.</p>
                            <p>Please contact our support team to register and gain access to your projects.</p>
                        </div>
                    )
                }


                <Form onSubmit={handleSubmit}>
                    <ControlSection
                        //saveText='Save draft'
                        errors={formErrors}
                        errorMessage={'Please fill in all required fields'}
                        controlText='Next Step'
                        onClickSave={() => {
                        }}
                        onClickControl={() => {
                        }}/>
                </Form>
            </FormWrapper>
        </>
    );
};