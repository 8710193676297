import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { components } from 'react-select';

export const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      {children}
    </components.Control>
  );
};

export const MultiValueRemove = ({ children, ...props }) => {
  return (
    <components.MultiValueRemove {...props}>
      <FontAwesomeIcon 
        icon={faCircleXmark} 
        style={{ color: 'black' }} 
        type="solid" />
    </components.MultiValueRemove>
  );
};

export const customStyles = {
  multiValue: (base) => ({
    ...base,
    backgroundColor: "var(--light-gray-color)",
    borderRadius: "0.375rem",
    padding: "6px",
    fontSize: "18px"
  }),
  multiValueRemove: (base) => ({
    ...base,
    ":hover": null
  })
};