import { useEffect, useState } from 'react';
import { KnowledgeObjectForm } from './KnowledgeObjectForm/KnowledgeObjectForm';
import { KnowledgeObjectSubmissionOverview } from './KnowledgeObjectSubmissionOverview';
import { KO_FORM_VIEW, KO_PREVIEW } from '../../../../values/constants';
import './KnowledgeObjectSubmission.css';

export const KnowledgeObjectSubmission = ({
  userId,
  project, 
  currentKnowledgeObject, 
  handleSubmit,
  handleCurrentKnowledgeObject, 
  handleNextStep,
  goToProjectStep
}) => {
 
  const [view, setView] = useState(KO_FORM_VIEW);
  const [elementId, setElementId] = useState('');

  const handleKnowledgeObjectChange = (knowledgeObject) => {
    handleCurrentKnowledgeObject(knowledgeObject);
  };

  const onSubmit = () => {
    handleSubmit(currentKnowledgeObject);
    setView(KO_PREVIEW);
    document.getElementById('form-nav').scrollIntoView();
  };

  const handleFormView = (view) => {
    setView(view);
  };

  const editInput = (inputId) => {
    handleFormView(KO_FORM_VIEW);
    setElementId(inputId);
  }

  useEffect(() => {
    if (elementId && elementId.length > 0) {
      const element = document.getElementById(elementId);
      if(element!=null)
      {
        element.scrollIntoView();
      }else{
        console.log(`Element ${elementId} not found`);
      }
    } else {
      document.getElementById('form-nav').scrollIntoView();
    }
  }, [elementId]);

  return (
    <>
      {view === KO_FORM_VIEW ? (
        <KnowledgeObjectForm
          userId={userId}
          project={project}
          knowledgeObject={currentKnowledgeObject}
          handleKnowledgeObjectChange={handleKnowledgeObjectChange}
          onSubmit={onSubmit}
          goToProjectStep={goToProjectStep}/>
      ) : (
        <KnowledgeObjectSubmissionOverview
          project={project}
          knowledgeObject={currentKnowledgeObject}
          handleKnowledgeObjectChange={handleKnowledgeObjectChange}
          handleFormView={handleFormView}
          handleFinalization={handleNextStep}
          goToProjectStep={goToProjectStep}
          editInput={editInput}/>
      )}
    </>
  );
};