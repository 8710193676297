import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FieldBox } from "../../../../components/Form/Containers/FieldBox/FieldBox";
import { FormWrapper } from "../../../../components/Form/Containers/FormWrapper/FormWrapper";
import { PropertyOverview } from "../../../../components/Form/Containers/PropertyOverview/PropertyOverview";
import { ButtonGreen } from "../../../../components/General/Buttons/ButtonGreen/ButtonGreen";
import { Line } from "../../../../components/General/Line/Line";
import { ControlSection } from "../../../../components/Form/ControlSection/ControlSection";
import { KO_FORM_VIEW, KO_PREVIEW } from "../../../../values/constants";
import { ProjectCard } from "../../../../components/Form/ProjectCard/ProjectCard";

export const KnowledgeObjectSubmissionOverview = ({ 
  project, 
  knowledgeObject, 
  handleKnowledgeObjectChange, 
  handleFormView, 
  handleFinalization, 
  goToProjectStep, 
  editInput 
}) => {

  const addKnowledgeObject = () => {
    handleKnowledgeObjectChange({
      id: '',
      files: [],
      fileType: {},
      title:'',
      summary: '',
      keywords: [],
      topics: [],
      subtopics: [],
      creators: [],
      license: {label: 'CC BY'},
      date: '',
      purpose: [],
      locations: []
    });
    handleFormView(KO_FORM_VIEW)
  };

  const onFinalization = (event) => {
    handleFinalization(event);
    document.getElementById('form-nav').scrollIntoView();
  };

  return (
    <>
      <FormWrapper>
        <ProjectCard
          project={project}
          goToProjectStep={goToProjectStep}/>
        <FieldBox>
          <PropertyOverview
            title="Files"
            value={knowledgeObject.files.map(fileWrapper => fileWrapper.file.name).join(', ')}
            linkText="Upload different files"
            editInput={editInput}
            id='file-input-file'/>
          <Line/>
          <PropertyOverview
              title="Title"
              value={knowledgeObject.title}
              isLongText={false}
              linkText="Edit title"
              editInput={editInput}
              id='text-title'/>
          <Line />
          <PropertyOverview 
            title="Description"
            value={knowledgeObject.summary} 
            isLongText={true}
            linkText="Edit summary"
            editInput={editInput}
            id='text-area-summary'/>
          <Line />
          <PropertyOverview 
            title="Category"
            value={knowledgeObject.fileType.label}
            isLongText={false}
            linkText="Edit file type"
            editInput={editInput}
            id='radio-fileType'/>
          <Line />
          <PropertyOverview 
            title="Keywords" 
            value={knowledgeObject.keywords.join(', ')}
            isLongText={false}
            linkText="Edit keywords"
            editInput={editInput}
            id='text-area-keywords'/>
          <Line />
          <PropertyOverview 
            title="Topics" 
            value={knowledgeObject.topics.map(topic => topic.label).join(', ')}
            isLongText={false}
            linkText="Edit topics"
            editInput={editInput}
            id='checkbox-topics'/>
          <Line />
          <PropertyOverview 
            title="Subtopics" 
            value={knowledgeObject.subtopics.map(subtopic => subtopic.label).join(', ')}
            isLongText={false}
            linkText="Edit subtopics"
            editInput={editInput}
            id='checkbox-topics'/>
          <Line/>
          <PropertyOverview 
            title="Creator(s)"
            value={knowledgeObject.creators.map((creator,index)=>{
              return `${creator.name} (${creator.email}), `;
            })}
            isLongText={false}
            linkText="Edit creators"
            editInput={editInput}
            id='multitext-creators'/>
          <Line/>
          <PropertyOverview 
            title="License" 
            value={knowledgeObject.license.label}
            isLongText={false}
            linkText="Edit license"
            editInput={editInput}
            id='radio-license'/>
          <Line/>
          <PropertyOverview 
            title="Intended purpose"
            value={knowledgeObject.purpose.map(purpose => purpose.label).join(', ')}
            isLongText={false}
            linkText="Edit purpose"
            editInput={editInput}
            id='radio-purpose'/>
          <Line/>
          <PropertyOverview 
            title="Date of completion"
            value={knowledgeObject.date}
            isLongText={false}
            linkText="Edit date"
            editInput={editInput}
            id='date-select-date'/>
          <Line/>
          <PropertyOverview 
            title="Geographic location(s)"
            value={knowledgeObject.locations.join(', ')}
            isLongText={false}
            linkText="Edit Location"
            editInput={editInput}
            id='select-locations'/>
        </FieldBox>
      </FormWrapper>
      <ButtonGreen 
        bootstrap="mt-4 mb-2 col-12" 
        text='Add another Knowledge Object'
        onClick={addKnowledgeObject}/>
      <ControlSection
        // saveText='Save draft'
        controlText='Finalize Contribution'
        onClickSave={() => {}}
        onClickControl={(e) => onFinalization(e)}
        icon={faArrowRight}/>
    </>
  );
};