import { useEffect, useState } from "react";
import Select from "react-select";
import { days, months, years } from "../../../../values/constants";
import { Control, MultiValueRemove, customStyles } from "../../../../helpers/selectDropDownOptions";
import {RequiredIndicator} from "../RequiredIndicator";

export const DateSelection = ({ title, name, description, handleChange, value, validation,required=false }) => {
  const dateArr = value !== '' ? value.split('-') : '';
  const [day, setDay] = useState(dateArr[0] ?? '');
  const [month, setMonth] = useState(dateArr[1] ?? '');
  const [year, setYear] = useState(dateArr[2] ?? ''); 

  const onChangeDay = (day) => {
    setDay(day.value);
  };
  const onChangeMonth = (month) => {
    setMonth(month.value);
  };
  const onChangeYear = (year) => {
    setYear(year.value);
  };

  useEffect(() => {
    if (day === '' || month === '' || year === '') {
      handleChange('')
    } else {
      handleChange(`${day}-${month}-${year}`);
    }
  }, [day, month, year]);

  return (
    <>
    <p className='fw-bolder fs-5' id={`date-${name}`}>
      {required && <RequiredIndicator/>}
      {title}
    </p>
    <p>{description}</p>
    <div className="d-flex">
      <div className="d-flex flex-column m-2">
        <p>Day</p>
        <Select 
          closeMenuOnSelect={false} 
          value={{label: day, value: day}} 
          onChange={onChangeDay}
          styles={customStyles}
          components={{ Control, IndicatorSeparator:() => null, MultiValueRemove, ClearIndicator: () => null }}
          options={days.map((day) => ({
            label: day,
            value: day
          }))}/>
      </div>
      <div className="d-flex flex-column m-2">
        <p>Month</p>
        <Select 
          closeMenuOnSelect={false} 
          value={{ label: month, value: month }} 
          onChange={onChangeMonth}
          styles={customStyles}
          components={{ Control, IndicatorSeparator:() => null, MultiValueRemove, ClearIndicator: () => null }}
          options={months.map((month) => ({
            label: month,
            value: month
          }))}/>
      </div>
      <div className="d-flex flex-column m-2">
        <p>Year</p>
        <Select 
          closeMenuOnSelect={false} 
          value={{ label: year, value: year }} 
          onChange={onChangeYear}
          styles={customStyles}
          components={{ Control, IndicatorSeparator:() => null, MultiValueRemove, ClearIndicator: () => null }}
          options={years.map((year) => ({
            label: year,
            value: year
          }))}/>
      </div>
    </div>
    {validation && validation.errorMessage && (
      <p className="mt-2" style={{ color: 'var(--red-color)'}}>
        Please Select {title}
      </p>
    )}
    </>
  );
};