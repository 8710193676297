import { useEffect, useState } from "react";
import {DEFAULTS} from "../defaults/defaults";

const AUTHORIZATION_KEY = process.env.CREDENTIALS??DEFAULTS.AUTHORIZATION;

export const useDataFetching = (dataSource) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState('');

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const data = await fetch(dataSource,DEFAULTS.HEADERS.GET_HEADERS);

        const result = await data.json();

        if (result) {
          setData(result);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        setError(e.message);
        console.log(e);
      }
    };

    console.log('data is',data);

    fetchData();
  }, [dataSource]);

  return [loading, error, data];
};