import { useEffect, useState } from 'react';
import { Control, MultiValueRemove, customStyles } from '../../../../helpers/selectDropDownOptions';
import Select from 'react-select';
import './SelectDropdown.css';
import {OffCanvasHelp} from "../../../General/OffCanvasHelp/OffCanvasHelp";
import {RequiredIndicator} from "../RequiredIndicator";

export const SelectDropdown = ({ 
  title, 
  name,
  description, 
  options, 
  values,
  help_id,
  handleSelected,
  placeholder,
  validation,
  required=false
}) => {

  const [selected, setSelected] = useState(values.map((language) => ({
    value: language,
    label: language
  })) ?? []);

  const onChange = (option) => {
    setSelected(option);
  };

  useEffect(() => {
    handleSelected(selected);
  }, [selected]);

  return (
    <>
      <p className='fw-bolder fs-5' id={`select-${name}`}>
        {required && <RequiredIndicator/>}
        {title}
        <OffCanvasHelp help_id={help_id} title={title}></OffCanvasHelp>
      </p>
      <p>{description}</p>
      <Select 
        closeMenuOnSelect={false} 
        value={selected} 
        onChange={onChange}
        isMulti
        placeholder={placeholder??'Select...'}
        styles={customStyles}
        components={{ Control, IndicatorSeparator:() => null, MultiValueRemove, ClearIndicator: () => null }}
        options={options}/>
      {validation && validation.errorMessage && (
        <p className="mt-2" style={{ color: 'var(--red-color)'}}>
          Please Select {title}
        </p>
      )}
    </>
  );
};