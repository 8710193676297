"use client";

import {useState, useEffect} from "react";
import Header from "../../components/Header/Header";
import { useMultiStepForm } from "../../hooks/useMultiStepForm";
import { ProjectInfo } from "./Steps/ProjectInfo/ProjectInfo";
import { KnowledgeObjectSubmission } from "./Steps/KnowledgeObjectSubmission/KnowledgeObjectSubmission";
import { ReviewUpload } from "./Steps/ReviewUpload/ReviewUpload";
import { FormNav } from "./FormNav/FormNav";
import { submitKORequest } from "../../services/FormService";
import { v4 } from 'uuid';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {DEFAULTS} from "../../defaults/defaults";

export const UploadForm = ({
  userId = -1,
  uploadId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15), //random string if as default
  successCallback = (payload) => {
    alert('the KO has been successfully submitted!');
  },
  projects = DEFAULTS.projects,
}) => {
  const [submitForm, setSubmitForm] = useState(false);
  // Selected project from search results
  const [selectedProject, setSelectedProject] = useState({});
  // Submitted Knowledge Objects
  const [knowledgeObjects, setKnowledgeObjects] = useState([]);
  const [currentKnowledgeObject, setCurrentKnowledgeObject] = useState({
    id: '',
    title:'',
    files: [],
    fileType: '',
    summary: '',
    keywords: [],
    topics: [],
    subtopics: [],
    fileTypeCategories: [],
    creators: [],
    license: '',
    purpose: [],
    date: '',
    locations: []
  });


  // Handler to set the selected project from search results
   const handleSelectedProject = (project) => {
    setSelectedProject(project);
  };


  // Handle Knowledge Object submission
  const handleKnowledgeObjectSubmission = (knowledgeObject) => {
    setKnowledgeObjects([...knowledgeObjects, knowledgeObject]);
  };

  const handleCurrentKnowledgeObject = (knowledgeObject) => {
    setCurrentKnowledgeObject(knowledgeObject);
  };

  // Go to next Step
  const handleNextStep = () => {
    next();
  };

  // Adds knowledge object to an array for submission
  const handleKOSubmit = (knowledgeObject) => {
    if (!knowledgeObject.id || knowledgeObject.id === '') {
      const ko = {...knowledgeObject, id: v4() };
      setCurrentKnowledgeObject(ko);
      setKnowledgeObjects([...knowledgeObjects, ko]);
    } else {
      const index = knowledgeObjects.findIndex(ko => {
        return ko.id === knowledgeObject.id;
      });
      if (index || index >= 0) {
        const editedKOs = knowledgeObjects.slice();
        editedKOs[index] = currentKnowledgeObject;
        setKnowledgeObjects(editedKOs);
      }
    }
  };

  // Save draft of already given information
  const handleSaveDraft = (event) => {
    event.preventDefault();
    alert(JSON.stringify('To be implemented...'));
  };

  // Custom hook for handling steps into the form
  const { currentStepIndex, step, isFirstStep, isLastStep, goTo, next, back } = useMultiStepForm({ steps: 3});

  // Navigate to second step
  const goToProjectStep = () => {
    goTo(0);
  };

  // Navigate to second step
  const goToKnowledgeSubmissionStep = (knowledgeObject) => {
    setCurrentKnowledgeObject(knowledgeObject);
    goTo(1);
  };

  const submitKnowledgeObjects = () => {
    setSubmitForm(true);
    submitKORequest({ user_id:userId,ko_upload_collection_id:uploadId,project: selectedProject, knowledgeObjects }).then((result) => {
      setSubmitForm(false)
      if(!result.success)
      {
        alert('An error occurred while trying to save your contribution. Please try again later!')
        return false;
      }

      // console.log(`Submitted ${knowledgeObjects.length} knowledge objects.`);
      setSelectedProject({})
      setKnowledgeObjects([]);
      setCurrentKnowledgeObject({
        id: '',
        files: [],
        fileType: '',
        title: '',
        summary: '',
        keywords: [],
        topics: [],
        subtopics: [],
        creators: [],
        license: '',
        purpose: '',
        date: '',
        locations: []
      });

      goTo(0);
      return successCallback != undefined
          ? successCallback(
              result["success - knowledge object(s) updated"] ?? []
          )
          : null;
    });
  }

  const formSteps = [
    <ProjectInfo
      selectedProject={selectedProject}
      projects={projects}
      setSelectedAvailableProject={handleSelectedProject}
      handleNextStep={handleNextStep}/>,
    <KnowledgeObjectSubmission
      userId={userId}
      project={selectedProject}
      currentKnowledgeObject={currentKnowledgeObject}
      handleKnowledgeObjects={handleKnowledgeObjectSubmission}
      handleCurrentKnowledgeObject={handleCurrentKnowledgeObject}
      handleSubmit={handleKOSubmit}
      handleNextStep={handleNextStep}
      goToProjectStep={goToProjectStep}/>,
    <ReviewUpload 
      project={selectedProject}
      knowledgeObjects={knowledgeObjects}
      goToKnowledgeSubmissionStep={goToKnowledgeSubmissionStep}
      goToProjectStep={goToProjectStep}
      formPostInProgress={submitForm}
      onSubmit={submitKnowledgeObjects}/>
  ];

  useEffect(() => {
    console.log('Knowledge Object: ', currentKnowledgeObject)
  },[currentKnowledgeObject]);

  return (
    <>
      <Header
        title={'Upload your project'}
        pageDescription={'Single object upload form for EU-FarmBook. For large scale automated uploads of already structured metadata, please contact us.'}/>
      <div className="col-6 offset-3 mb-5">
        <FormNav goTo={goTo} currentStepIndex={currentStepIndex} />
        <div className="d-flex flex-column col-12 mt-5">
          {formSteps[currentStepIndex]}
        </div>
      </div>
    </>
  );
};