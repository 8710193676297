import {ButtonPrimary} from "../../General/Buttons/ButtonPrimary/ButtonPrimary";
import {InfoText} from "../../General/InfoText/InfoText";

export const ControlSection = ({formPostInProgress,controlText, saveText, errors, errorMessage, onClickSave, onClickControl, icon}) => {
    const hasError = Object.values(errors??{}).some(field => {
            return field.isRequired && field.errorMessage;
        });
    return (
        <>
            <div className={`d-flex flex-row ${saveText ? 'justify-content-between' : 'justify-content-end'} mt-3`}>
                {saveText && <ButtonPrimary text={saveText} variant="outline-primary" onClick={onClickSave}/>}
                { hasError && errorMessage &&
                    <div className='mx-2 d-flex align-items-center'>
                        <em className='text-danger fw-bold'>{errorMessage}</em>
                    </div>
                }
                {
                    formPostInProgress && <>
                        <em>Your submission is being processed. It may take a few seconds. Please wait.</em>
                    </>
                }
                {
                    !formPostInProgress && <ButtonPrimary text={controlText} variant="primary" onClick={onClickControl} icon={icon} type="submit"/>
                }
            </div>
        </>
    );
};