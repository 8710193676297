import { FileHeader } from "../../Fields/FileInput/FileHeader/Fileheader";
import { FilePreview } from "../../Fields/FileInput/FilePreview/FilePreview";
import { ButtonLink } from "../../../General/Buttons/ButtonLink/ButtonLink";

export const PropertyOverview = ({ title, value, isLongText, linkText, editInput, file, id }) => {
  const onClick = () => {
    editInput(id);
  };
  return (
    <>
      {file ? (
        <FilePreview
          file={file}
          status='Uploaded'/>
        ) : (
        isLongText ? (
          <div className="d-flex flex-column mx-1 justify-content-start">
            <p className='fw-bolder fs-5'>{title}</p>
            <p className='fw-normal fs-5'>{value}</p> 
          </div>
        ) : (
          <div className="d-flex flex-row mx-1 justify-content-start">
            <div className="col-3 flex-row">
              <p className='fw-bolder fs-5'>
                {title}: 
              </p>
            </div>
            <div className="col-6 flex-row">
              <p className='fw-normal fs-5'>{value}</p> 
            </div>
          </div>
        )
      )}
      {linkText ? (
        <ButtonLink onClick={onClick} text={linkText}/>
      ) : (
        <></>
      )}
    </>
  );
};