import './Header.css';

const Header = ({ title, pageDescription }) => {
  return (
    <div className="header py-3">
      <div className="header-wrapper col-6 offset-3 py-3">
        <div className="header-text py-3">
          <h1 className="header-title align-left">{title}</h1>
          <p className="align-left">{pageDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default Header;