import {useEffect, useState} from "react";
import {uploadFile} from "../../../../../services/FormService";
import {ProgressBar} from "react-bootstrap";
import {FieldBox} from "../../../Containers/FieldBox/FieldBox";
import {FileHeader} from "../FileHeader/Fileheader";
import {DEFAULTS} from "../../../../../defaults/defaults";

export const FileUploadWithProgress = ({
    userId,
  file,
  onDelete,
  handleFile,
  handleLocalChange,
  updateFileOperation,
  onOpenFileSelector,
  file_index,
  languages = []
}) => {
    const [progress, setProgress] = useState(0);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (file !== {} && file.uploaded) {
            setProgress(100);
        } else {
            const upload = async () => {
                const data = await uploadFile(file, setProgress,userId);

                let newFile = file;
                newFile.server_response = data;
                newFile.uid = data['_id'] ?? (new Date).getMilliseconds()+'_'+Math.random().toString(36).substring(2, 15);
                newFile.locale=file.locale ?? DEFAULTS.FILE_LOCALE;
                updateFileOperation(file,newFile);
                setErrors(
                    data._id === undefined
                        ? [{
                            code: 'file-exists',
                            message: data.detail ?? `File upload failed. (${data.detail ?? 'No detail'})`
                        }]
                        : []
                );
            }

            upload();
        }

    }, []);

    let status = errors.length > 0
        ? 'Failed'
        : progress !== 100 ? 'Uploading...' : 'Uploaded';

    return (
        <>
            <div className="mt-3" key={'file-'+file.name}>
                <FieldBox>
                    <FileHeader file={file} file_index={file_index} onDelete={onDelete} errors={errors}
                                status={status}/>
                    {progress < 100 && <ProgressBar now={progress} style={{height: "4px"}}/>}
                    <div className="d-flex justify-content-between">
                        <select onChange={(e) => handleLocalChange(file, file_index, e)}
                                value={file.locale ?? 'English'} name="language" id="" key={'file-locale-' + file.name}
                                className='form-control w-25'>
                            {
                                languages.map(
                                    (language) => (
                                        <option value={language.value}>{language.label}</option>
                                    )
                                )
                            }
                        </select>
                        <a href="javascript:void(0)" onClick={onOpenFileSelector}>
                            + Add a translated version
                        </a>
                    </div>
                </FieldBox>
            </div>
        </>
    );
}

