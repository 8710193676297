import { Form, InputGroup } from "react-bootstrap";
import {OffCanvasHelp} from "../../../General/OffCanvasHelp/OffCanvasHelp";
import {RequiredIndicator} from "../RequiredIndicator";

export const TextArea = ({ 
  title, 
  description, 
  rows, 
  name, 
  placeholder, 
  handleInputChange, 
  value,
  help_id,
  validation,
  required = false
}) => {
  return (
    <>
      <p className='fw-bolder fs-5' id={`text-area-${name}`}>
          {required && <RequiredIndicator/>}
          {title}
          <OffCanvasHelp help_id={help_id} title={title}></OffCanvasHelp>
      </p>
      <p>{description}</p>
      <InputGroup>
        <Form.Control
          as='textarea'
          rows={rows}
          className="mr-2"
          placeholder={placeholder}
          name={name}
          onChange={handleInputChange}
          value={value}
          />
      </InputGroup>
      {validation && validation.errorMessage && (
        <p className="mt-2" style={{ color: 'var(--red-color)'}}>
          Please enter {title}
        </p>
      )}
    </>
  );
};