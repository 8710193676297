import { FileHeader } from "../FileHeader/Fileheader";
import { RedBox } from "../../../Containers/RedBox/RedBox";
import { ProgressBar } from "react-bootstrap";

export const FileUploadError = ({ file, errors, onDelete }) => {
  return (
    <>
    <div className='red-box mt-3'>
      <FileHeader file={file} onDelete={onDelete} status='Failed' errors={errors}/>
      <ProgressBar now={100} variant='danger' style={{ height: "4px" }} />
    </div>
    </>
  );
};