import { useState } from "react";
import { mapObject } from "../helpers/helpers";

export const useValidation = (form) => {

  const onValidate = (value, name) => {
    console.log('onvalidate', value, name, formErrors, 'formErrors');
    setFormErrors((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMessage: value}
    }));
  };

  const optional_fields = ['id','doi'];
  const [formErrors, setFormErrors] = useState(mapObject(form, key => ({
    isRequired: !optional_fields.includes(key),
    errorMessage: false,
    onValidate: onValidate
  })));

  const validateForm = ({ formData, isSubmited }) => {
    console.log('ps/validation', formData, isSubmited, 'formData')
    let isInvalid = false;

    if(
        formData.selectedProject !==undefined && formData.selectedProject.id === undefined
    ){
      onValidate(true, 'selectedProject');
      return false;
    }

    Object.keys(formErrors).forEach((key) => {

      const errObj = formErrors[key];
      if(optional_fields.includes(key)) return false;

      if(
          (key === 'file' && errObj.isRequired && (Object.keys(formData[key]).length === 0 || formData[key].fileName === ''))
          ||
          (key !== 'file' && errObj.isRequired && (!formData[key] || formData[key].length < 1))
      )
      {
        isInvalid = true;
        onValidate(true, key);
      } else {
        onValidate(false, key);
      }

    });
    return !isInvalid;
  };

  const validateOnChange = ({ formData, isSubmited }) => {
    Object.keys(formErrors).forEach((key) => {
      const errObj = formErrors[key];
      if (key !== 'id') {
        if (key === 'files') {
          if (errObj.isRequired && (Object.keys(formData[key]).length === 0 || formData[key].fileName === '') && isSubmited) {
            onValidate(true, key);
          } else {
            onValidate(false, key);
          }
        } else {
          if (errObj.isRequired && (!formData[key] || formData[key].length < 1) && isSubmited) {
            onValidate(true, key);
          } else {
            onValidate(false, key);
          }
        }
      }
    });
  };

  return [formErrors, validateForm, validateOnChange];
};