import {ButtonLink} from "../Buttons/ButtonLink/ButtonLink";

export const LoadingState = ({
    show_loading = false,
    show_error = false,
    loadingText = 'Loading...',
    error_text = 'The system encountered an error while loading the data',
    allow_reload = false,
    reload_callback = () => {},
    reload_btn_text = 'Click here to reload!'
}) => {
    return (
        <>
            {show_loading && <p>{loadingText}</p>}
            {show_error && <p>{error_text}</p>}
            {allow_reload && show_error && !show_loading &&
                <ButtonLink
                    text={reload_btn_text}
                    onClick={reload_callback}
                />
            }
        </>
    )
}