import {PropertyOverview} from "../../Containers/PropertyOverview/PropertyOverview";


export const KnowledgeObjectDetails = ({knowledgeObject}) => {
    return (
        <>
            <PropertyOverview
                title="Title"
                value={knowledgeObject.title}
                isLongText={false}/>
            <PropertyOverview
                title="Description"
                value={knowledgeObject.summary}
                isLongText={false}/>
            <PropertyOverview
                title="Category"
                value={knowledgeObject.fileType.label}
                isLongText={false}/>
            <PropertyOverview
                title="Type"
                value={knowledgeObject.fileTypeCategories.map(category => category.label).join(', ')}
                isLongText={false}/>
            <PropertyOverview
                title="Keywords"
                value={knowledgeObject.keywords.join(', ')}
                isLongText={false}/>
            <PropertyOverview
                title="Topics"
                value={knowledgeObject.topics.map(topic => topic.label).join(', ')}
                isLongText={false}/>
            <PropertyOverview
                title="Subtopics"
                value={knowledgeObject.subtopics.map(subtopic => subtopic.label).join(', ')}
                isLongText={false}/>
            <PropertyOverview
                title="Creator(s)"
                value={knowledgeObject.creators.map(creator => `${creator.name} (${creator.email})`).join(', ')}
                isLongText={false}/>
            <PropertyOverview
                title="License"
                value={knowledgeObject.license.label}
                isLongText={false}/>
        </>
    );
};