export const KO_FORM_VIEW = 1;
export const KO_PREVIEW = 2;

export const CURRENT_YEAR = new Date().getFullYear();

export const years = Array.from({length: 25  }, (x, i) => ((CURRENT_YEAR-i)).toString());

export const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const days = [
  '01', '02', '03', '04', '05', '06', '07',
  '08', '09', '10', '11', '12', '13', '14', 
  '15', '16', '17', '18', '19', '20', '21', 
  '22', '23', '24', '25', '26', '27', '28',
  '29', '30', '31'
];