const DEFAULTS = {
    API_URL:"https://backend-core.dev.farmbook.ugent.be/api/",
    AUTHORIZATION:"Basic ZmFybWJvb2s6NU5SMTkxWGJhTzRBWE0=",
    LICENSE:{label: 'CC BY'},
    FILE_LOCALE: "English"
};

DEFAULTS.HEADERS = {
    GET_HEADERS:{
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        headers:{
            'Authorization': DEFAULTS.AUTHORIZATION??""
        }
    },
};

DEFAULTS.projects = [
    {
        "id": "63eb53ba1fd480aef8bc925ea1e328683333120c9c88c8c43d97d3ce91c2f125",
        "name": "EU-FarmBook: supporting knowledge exchange between all AKIS actors in the European Union",
        "acronym": "EU-FarmBook",
        "url": "",
        "startDate": "2017-04-01",
        "description": "Acute myeloid leukemia (AML) is the most common of the acute leukemias among adults. Relapsed AML patients are frequent but difficult to treat since effective therapies are limited. This mediocre outcome can be partially explained by the presence of leukemia stem cells (LSC)...",
        "endDate": "2019-03-31",
        "grantId": "101060382",
        "role": "Coordinator"
    },{
        "id": "63eb53ba1fd480aeabce328683333120c9c88c8c43d97d3ce91c2f125",
        "name": "AFINET - Agroforestry Innovation Networks",
        "acronym": "AFINET",
        "url": "",
        "startDate": "2017-04-01",
        "description": "Acute myeloid leukemia (AML) is the most common of the acute leukemias among adults. Relapsed AML patients are frequent but difficult to treat since effective therapies are limited. This mediocre outcome can be partially explained by the presence of leukemia stem cells (LSC)...",
        "endDate": "2019-03-31",
        "grantId": "727872",
        "role": "Coordinator"
    },{
        "id": "63eb53bas1fd480aef8bc925ea1e328683333120c9c88c8c43d97d3ce91c2f125",
        "name": "Connecting Thematic Networks towards a stronger EU agricultural knowledge base",
        "acronym": "EURAKNOS",
        "url": "",
        "startDate": "2017-04-01",
        "description": "EURAKNOS wants to reinforce the EU agricultural knowledge base by building the blueprint for a datasystem to enable the farming/rural community easier access to best practices from all EU H2020 Thematic Networks.",
        "endDate": "2019-03-31",
        "grantId": "817863",
        "role": "Coordinator"
    },,{
        "id": "63eb53bas1fd480aef8bc925ea1e328683333120c9c88cab8c43d97d3ce91c2f125",
        "name": "European Knowledge repository for best agricultural practices",
        "acronym": "EUREKA",
        "url": "",
        "startDate": "2017-04-01",
        "description": "EUREKA was an EU-funded action that aimed to help ensure the longer-term and wider use by farmers, foresters and other rural businesses of the practical knowledge and innovative solutions that are generated by Horizon 2020 multi-actor projects.",
        "endDate": "2019-03-31",
        "grantId": "862790",
        "role": "Coordinator"
    },
];

export { DEFAULTS }