import { useState } from "react";
import { Line } from "../../General/Line/Line";
import { FieldBox } from "../Containers/FieldBox/FieldBox";
import { ButtonPrimary } from "../../General/Buttons/ButtonPrimary/ButtonPrimary";
import { KnowledgeObjectDetails } from "./KnowledgeObjectDetails/KnowledgeObjectDetails";
import { KnowledgeObjectDetailsCollapsed } from "./KnowledgeObjectDetailsCollapsed/KnowledgeObjectDetailsCollapsed";
import { ButtonGreen } from "../../General/Buttons/ButtonGreen/ButtonGreen";
import { faMinus, faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FilePreview } from "../Fields/FileInput/FilePreview/FilePreview";
import {PropertyOverview} from "../Containers/PropertyOverview/PropertyOverview";

export const KnowledgeObjectPreview = ({ knowledgeObject, goToKnowledgeSubmissionStep }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  const editKnowledgeObject = (event) => {
    event.preventDefault();
    goToKnowledgeSubmissionStep(knowledgeObject);
  };

  return (
    <FieldBox>
      <div className="collapsible">
        <PropertyOverview
            title="Files"
            value={knowledgeObject.files.map(fileWrapper => fileWrapper.file.name).join(', ')}
            id='file-input-file'/>
        <Line/>
        {!open ? (
          <div>
            <KnowledgeObjectDetailsCollapsed
              knowledgeObject={knowledgeObject}
              onClick={toggle}/>
          </div>
        ) : (
          <div>
            <KnowledgeObjectDetails knowledgeObject={knowledgeObject}/>
          </div>
        )}
        <div className="d-flex flex-row flex-wrap justify-content-start">
          <ButtonPrimary 
            text={open ? "Hide all" : "Show all"}
            onClick={toggle}
            icon={open ? faMinus : faPlus}
            variant='primary'
            padding={7}
            size={20}
            fontWeight='bold'/>
          <ButtonGreen
            text="Edit knowledge object"
            onClick={editKnowledgeObject}
            icon={faPencil}
            bootstrap='d-flex flex-row align-items-center'
            padding={5}
            size={20}
            fontWeight='bold'
            color='var(--white-color)'/>
        </div>
      </div>
    </FieldBox>
  );
};