import { faFile, faCircleXmark, faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FileHeader = ({ file, onDelete, status, errors, isPreview = false, file_index }) => {
  const handleDelete = (file,e) => {
    e.preventDefault()
    if(window.confirm(`Are you sure you want to delete file "${file.name??''}"?`)){
      onDelete(file,e);
    }
  };
  return (
    <div className = {`${errors && errors.length>0 ? 'red-box':''}`}>
      <div className="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row flex-wrap justify-content-between align-items-between mb-4">
        <div className="col-6 align-items-baseline">
          <div className="d-flex flex-row align-items-center">
            <FontAwesomeIcon icon={faFile} style={{color: errors && errors.length > 0 ? "var(--dark-red-color)" : "var(--light-blue-color)"}} size="2xl"/>
            <div className="d-flex flex-column mx-3">
              <p className="">{file.name ? file.name : file.originalFilename}</p>
              <strong>{Math.floor(file.size/10000)/100} MB</strong>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-around align-items-end my-0">
          <a href="javascript:void(0)" className="mb-4" onClick={(e)=>{handleDelete(file,e)}} style={{ padding: '0', border: "none", background: "var(--color-white)", maxWidth: "fit-content" }}>
            <FontAwesomeIcon icon={faCircleXmark} style={{color: "var(--dark-blue-color)"}} size="xl"/>
          </a>
          <div className="d-flex flex-row align-items-center">
            <p className="m-2">{status}</p>
            {status === 'Uploaded' && 
                <FontAwesomeIcon 
                  icon={faCheckCircle} 
                  style={{ color: "var(--green-color)"}}/>
            }
          </div>
        </div>
      </div>
      <div>
        {errors && errors.map((error) => (
          <p className="fs-6 fw-light fst-italic" style={{ color: 'var(--dark-red-color)'}}>
            {error.code === 'file-invalid-type' ?
             'Filetype must be .pdf, .ppt, .key, .jpg, .png, .doc, .docx, .csv'
             : (error.code === 'file-too-large' ? 'File size is larger than 50 MB' : error.message)
            }
          </p>
        ))}
      </div>
    </div>
  );
};