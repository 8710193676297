import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ButtonGreen.css';

export const ButtonGreen = ({ text, onClick, bootstrap, icon, size, padding, fontWeight, color }) => {
  return (
    <div className='flex-column py-2'>
      <button 
      className={`button-green ${bootstrap} mx-2 p-2`} 
      onClick={onClick}
      style={{ 
        fontSize: `${size}px`, 
        whiteSpace: 'nowrap', 
        padding: `${padding}px`,
        fontWeight,
        color
      }}>
      {text}
      {icon ? (
          <FontAwesomeIcon className="mx-1" icon={icon}/>
        ) : (
          <></>
        )}
    </button>
    </div>
  );
};