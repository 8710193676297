import {ProjectListOption} from "./ProjectListOption";
import "./ProjectList.css"

export const ProjectList = ({ projects, handleSelectProject,selectedProject }) => {
    return (
        <div className="row project-list-container">
            {
                projects.map(
                    (project) => (
                        <ProjectListOption
                            key={'project-item-'+project.id}
                            handleSelectProject={handleSelectProject}
                            selectedProject={selectedProject}
                            project={project} />
                    )
                )
            }
        </div>
    );
};
