import { FieldBox } from "../Containers/FieldBox/FieldBox";
import { ButtonLink } from "../../General/Buttons/ButtonLink/ButtonLink";

export const ProjectCard = ({ project, goToProjectStep }) => {
    const doi =
        project.selectedProject!==undefined
            && project.selectedProject.identifiers !== undefined
            && project.selectedProject.identifiers.grantDoi !== undefined
        ? project.selectedProject.identifiers.grantDoi
        :'n/a';

  return (
    <FieldBox>
      <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
              <h5 className="col-3 my-2 mx-0">{project.acronym}</h5>
              {
                  project.grantId && (
                      <h6 className="col-4 m-2 text-end"><strong>ID:</strong> {project.grantId}</h6>
                  )
              }
          </div>
          <p>{project.name}</p>
          <div className="justify-content-end align-items-end d-flex">
              <ButtonLink className="col-3 m-2" text="Change project" onClick={goToProjectStep}/>
          </div>
      </div>
    </FieldBox>
  );
};