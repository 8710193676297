import { FormWrapper } from '../../../../components/Form/Containers/FormWrapper/FormWrapper';
import { useEffect } from 'react';
import { FieldBox } from '../../../../components/Form/Containers/FieldBox/FieldBox';
import { KnowledgeObjectPreview } from '../../../../components/Form/KnowledgeObjectPreview/KnowledgeObjectPreview';
import { ProjectCard } from '../../../../components/Form/ProjectCard/ProjectCard';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { ControlSection } from '../../../../components/Form/ControlSection/ControlSection';
import './ReviewUpload.css'

export const ReviewUpload = ({ project, knowledgeObjects, goToKnowledgeSubmissionStep, goToProjectStep,formPostInProgress, onSubmit }) => {
  return (
    <FormWrapper title='Step 3: Review & Upload'>
      <ProjectCard 
        project={project}
        goToProjectStep={goToProjectStep}/>
      {knowledgeObjects.length > 0 ? (
        knowledgeObjects.map((knowledgeObject) => (
         <KnowledgeObjectPreview 
          knowledgeObject={knowledgeObject}
          goToKnowledgeSubmissionStep={goToKnowledgeSubmissionStep}/>
        ))
      ) : (
        <FieldBox>
          <h2>There aren't any unsubmitted knowledge objects</h2>
        </FieldBox>
      )}

      <ControlSection
        formPostInProgress={formPostInProgress}
        controlText='Submit Contribution'
        onClickControl={onSubmit}
        icon={faPaperPlane}/>
    </FormWrapper>
  );
};