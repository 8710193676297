import './FormNav.css';

export const FormNav = ({ goTo, currentStepIndex }) => {

  const goToStep = (e, index) => {
    e.preventDefault();
    goTo(index);
  };

  return (
    <>
      <div className='d-flex flex-row justify-content-center col-12' id='form-nav'>
        <div className="form-steps flex-wrap col-12">
          <button  style={{zIndex:2}} className="step" onClick={() => {}}>
            <p className="txt-black">STEP 1</p>
            <p className={currentStepIndex === 0 ? "selected" : ""}>SELECT PROJECT</p>
          </button>
          <button style={{zIndex:1}} className="step" onClick={() => {}}>
            <p className="txt-black">STEP 2</p>
            <p className={currentStepIndex === 1 ? "selected" : ""}>KNOWLEDGE OBJECT SUBMISSION</p>
          </button>
          <button style={{zIndex:0}} className="step"  onClick={() => {}}>
            <p className="txt-black">STEP 3</p>
            <p className={currentStepIndex === 2 ? "selected" : ""}>REVIEW & UPLOAD</p>
          </button>
        </div>
      </div>
    </>
  );
};