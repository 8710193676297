import {Form, InputGroup} from "react-bootstrap";
import {OffCanvasHelp} from "../../../General/OffCanvasHelp/OffCanvasHelp";
import {RequiredIndicator} from "../RequiredIndicator";

export const TextInput = ({
    title,
    description,
    placeholder,
    name,
    handleInputChange,
    value,
    help_id,
    validation,
    required = false
}) => {
    return (
        <>
            <p className='fw-bolder fs-5' id={`text-${name}`}>
                {required && <RequiredIndicator/>}
                {title}
                <OffCanvasHelp help_id={help_id} title={title}></OffCanvasHelp>
            </p>
            <p>{description}</p>
            <InputGroup>
                <Form.Control
                    className="mr-2"
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    name={name}
                    value={value}
                />
            </InputGroup>
            {validation && validation.errorMessage && (
                <p className="mt-2" style={{color: 'var(--red-color)'}}>
                    Please enter {title}
                </p>
            )}
        </>
    );
};