import { FieldBox } from "../Containers/FieldBox/FieldBox";
import { ButtonLink } from "../../General/Buttons/ButtonLink/ButtonLink";

export const ProjectListOption = ({ project, handleSelectProject, selectedProject }) => {

    return (
            <div className={`
                    col-12 field-box mb-4 
                    ${project.id===selectedProject.id?'selected-project':''}
            `}>
                <div onClick={()=>{handleSelectProject(project)}} role="button">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5>{project.acronym}</h5>
                        <h5>{project.grantId}</h5>
                    </div>
                    <div className="mt-2">
                        <span className={'text-muted'}>{project.name}</span>
                    </div>
                </div>
            </div>
    );
};