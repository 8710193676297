import './App.css';
import { UploadForm } from './pages/UploadForm/UploadForm';


function App() {
  return (
      <UploadForm />
  );
}

export default App;
