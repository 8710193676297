import {useEffect, useState} from "react";
import {FieldBox} from "../../../../../components/Form/Containers/FieldBox/FieldBox";
import {FormWrapper} from "../../../../../components/Form/Containers/FormWrapper/FormWrapper";
import {ControlSection} from "../../../../../components/Form/ControlSection/ControlSection";
import {CheckBoxGroup} from "../../../../../components/Form/Fields/CheckBoxGroup/CheckBoxGroup";
import {FileInput} from "../../../../../components/Form/Fields/FileInput/FileInput";
import {RadioGroup} from "../../../../../components/Form/Fields/RadioGroup/RadioGroup";
import {SelectDropdown} from "../../../../../components/Form/Fields/SelectDropdown/SelectDropdown";
import {TextArea} from "../../../../../components/Form/Fields/TextArea/TextArea";
import {Line} from "../../../../../components/General/Line/Line";
import {useDataFetching} from "../../../../../hooks/useDataFetching";
import {
    subtopicsRequest,
    fieldOptionsListRequest,
    fileTypeCategoriesRequest
} from "../../../../../services/FormService";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Form} from "react-bootstrap";
import {useValidation} from "../../../../../hooks/useValidation";
import {ProjectCard} from "../../../../../components/Form/ProjectCard/ProjectCard";
import {DateSelection} from "../../../../../components/Form/Fields/DateSelection/DateSelection";
import {Creator} from "../../../../../components/Form/Fields/Creator/Creator";
import {TextInput} from "../../../../../components/Form/Fields/TextInput/TextInput";
import {ButtonLink} from "../../../../../components/General/Buttons/ButtonLink/ButtonLink";
import {LoadingState} from "../../../../../components/General/ListLoadingState/LoadingState";
import {FilePreview} from "../../../../../components/Form/Fields/FileInput/FilePreview/FilePreview";
import {DEFAULTS} from "../../../../../defaults/defaults";

export const KnowledgeObjectForm = ({
    userId,
    project,
    knowledgeObject,
    handleKnowledgeObjectChange,
    onSubmit,
    goToProjectStep
}) => {

    const AUTHORIZATION_KEY = process.env.CREDENTIALS??DEFAULTS.AUTHORIZATION;

    // Knowledge Object Input Data
    const [id, setId] = useState(knowledgeObject.id ?? '');
    const [files, setFiles] = useState(knowledgeObject.files);
    const [fileType, setFileType] = useState(knowledgeObject.fileType ?? '');
    // const [selectedFileTypeSubcategories, setSelectedFileTypeSubcategories] = useState(knowledgeObject.fileType ?? '');
    const [title, setTitle] = useState(knowledgeObject.title ?? '');
    const [summary, setSummary] = useState(knowledgeObject.summary ?? '');
    const [keywords, setKeywords] = useState(knowledgeObject.keywords ?? []);
    const [selectedTopics, setSelectedTopics] = useState(knowledgeObject.topics ?? []);
    const [selectedSubtopics, setSelectedSubtopics] = useState(knowledgeObject.subtopics ?? []);
    const [creators, setCreators] = useState(knowledgeObject.creators ?? []);
    const [license, setLicense] = useState(knowledgeObject.license ?? '');
    const [purpose, setPurpose] = useState(knowledgeObject.purpose ?? []);
    const [date, setDate] = useState(knowledgeObject.date ?? '');
    const [locations, setLocations] = useState(knowledgeObject.locations ?? []);
    const [isSubmited, setIsSubmited] = useState(false);
    const [doi, setDoi] = useState('');
    const [loadingLists, setLoadingLists] = useState(true);
    const [data_lists, setDataLists] = useState({});

    const [loadingSubtopics, errorSubtopics, subtopicData] = useDataFetching(subtopicsRequest(selectedTopics.map(topic => topic.id)));
    // const [loadingFileTypeSubcategories, errorFileTypeSubcategories, fileTypeSubcategoriesList] = useDataFetching(subtopicsRequest(selectedTopics.map(topic => topic.id)));
    const [fileTypeCategoriesList, setFileTypeCategoriesList] = useState([]);
    const [selectedFileTypeCategories, setSelectedFileTypeCategories] = useState(knowledgeObject.fileTypeCategories ?? []);

    // Form validation
    const [formErrors, validateForm, validateOnChange] = useValidation(knowledgeObject);

    const handleUploadedFile = (file) => {
        if(!file) return false;
        setFiles((prev)=>{
            return [...prev,...file];
        });
    };

    // useEffect(()=>{
    //     console.log('ps/m KnowledgeObjectForm files ',files);
    // },[files]);

    const updateFileOperation = (currentFile, newFile) =>{
        setFiles((prev)=>{
            return prev.map((fileWrapper)=>{
                if(fileWrapper.file.name===currentFile.name){
                    fileWrapper.file = newFile;
                }
                return fileWrapper;
            });
        });
    };

    const deleteFileOperation = (file) => {
        setFiles((prev)=>{
            return prev.filter((fileWrapper)=>{
                return fileWrapper.file.uid!==file.uid;
            });
        });
    }

    const handleFileLocaleSelection = (file,locale) => {
        setFiles((prev)=>{
            return prev.map((filewrapper)=>{
                if(filewrapper.file.name===file.name){
                    filewrapper.file.locale=locale;
                }
                return filewrapper;
            });
        });
    };

    const handleFileType = (fileType) => {
        setFileType(fileType);
    };

    const handleTitle = (event) => {
        event.preventDefault();
        setTitle(event.target.value);
    };

    const handleSummary = (event) => {
        event.preventDefault();
        setSummary(event.target.value);
    };

    const handleKeywords = (event) => {
        event.preventDefault();
        const keywordsText = event.target.value;
        const keywords = keywordsText.length > 0 ? keywordsText.split(',') : [];
        setKeywords(keywords);
    };

    const handleTopics = (topics) => {
        setSelectedTopics(topics);
    };

    const handleSubtopics = (subtopics) => {
        setSelectedSubtopics(subtopics);
    };

    const handleFileTypeCategories = (categories) => {
        setSelectedFileTypeCategories(categories);
    };

    const handleCreators = (creators) => {
        setCreators(creators);
    };

    const handleLicense = (license) => {
        setLicense(license);
    };

    const handlePurpose = (purpose) => {
        setPurpose(purpose);
    }

    const handleDate = (date) => {
        setDate(date)
    };

    const handleLocations = (locationObjects) => {
        if (locationObjects.length > 0) {
            setLocations(locationObjects.map(locObj => locObj.value))
        }
    };

    const reloadLists = (e) => {
        e.preventDefault();
        setLoadingLists(true);
    }

    const onSubmitForm = (event) => {
        if (validateForm({formData: knowledgeObject, isSubmited})) {
            event.preventDefault();
            setIsSubmited(false);
            onSubmit();
        } else {
            event.preventDefault();
            event.stopPropagation();
            setIsSubmited(true);
        }
    };

    useEffect(() => {
        const fetchListOptions = async () => {
            try {
                const response = await fetch(fieldOptionsListRequest(), DEFAULTS.HEADERS.GET_HEADERS);

                if (!response.ok) {
                    return false;
                }

                const data = await response.json();
                setDataLists(data);
            } catch (e) {
                console.error(e);
            } finally {
                setLoadingLists(false);
            }
        };

        if (loadingLists) {
            if(license==='')
                setLicense(DEFAULTS.LICENSE);
            fetchListOptions();
        }
    }, [loadingLists]);

    useEffect(()=>{
        const fetchfileTypeCategoriesList = async () => {
            const response = await fetch(fileTypeCategoriesRequest(fileType.id??0),DEFAULTS.HEADERS.GET_HEADERS);

            if (!response.ok) {
                return false;
            }

            const data = await response.json();
            setFileTypeCategoriesList(data);
        };

        // console.log('selected file type',fileType);
       fetchfileTypeCategoriesList();

    },[fileType]);

    useEffect(() => {

        validateOnChange({
            formData: {
                id,
                files,
                title,
                fileType,
                summary,
                keywords,
                topics: selectedTopics,
                subtopics: selectedSubtopics,
                fileTypeCategories: selectedFileTypeCategories,
                creators,
                license,
                purpose,
                date,
                locations
            }, isSubmited
        });

        handleKnowledgeObjectChange({
            id,
            files,
            fileType,
            doi,
            title,
            summary,
            keywords,
            topics: selectedTopics,
            subtopics: selectedSubtopics,
            fileTypeCategories: selectedFileTypeCategories,
            creators,
            license,
            purpose,
            date,
            locations
        });
    }, [
        id,
        files,
        fileType,
        title,
        summary,
        doi,
        keywords,
        selectedTopics,
        selectedSubtopics,
        selectedFileTypeCategories,
        creators,
        license,
        purpose,
        date,
        locations
    ]);

    return (
        <>
            <FormWrapper title='Step 2: Knowledge Object Submission'>
                <ProjectCard
                    project={project}
                    goToProjectStep={goToProjectStep}/>
                <Form noValidate onSubmit={onSubmitForm}>
                    <FieldBox>
                        <FileInput
                            userId={userId}
                            title='Upload a File'
                            required={true}
                            name='file'
                            description="You can upload files with a size up to 150MB."
                            handleFile={handleUploadedFile}
                            handleFileDeletion={deleteFileOperation}
                            handleFileLocaleSelection={handleFileLocaleSelection}
                            updateFileOperation={updateFileOperation}
                            validation={formErrors.file}
                            languages={data_lists.languageData && data_lists.languageData.map((language) => {
                                return {
                                    value: language.value,
                                    label: language.label
                                }
                            })}
                            files={files??[]}/>

                        <Line/>

                        <TextInput
                            title='Title'
                            required={true}
                            description='The title of the knowledge object.'
                            placeholder='Title'
                            name = 'title'
                            handleInputChange = {handleTitle}
                            value={title}
                            validation={formErrors.title}
                        />
                        <Line/>
                        <TextArea
                            title="Description"
                            required={true}
                            description="The short summary of the file (up to 500 words)."
                            rows={4}
                            help_id="file_summary"
                            name="summary"
                            placeholder="Summary"
                            handleInputChange={handleSummary}
                            value={summary}
                            validation={formErrors.summary}/>
                        <Line/>
                        <RadioGroup
                            title="Category"
                            required={true}
                            name="fileType"
                            options={data_lists.fileTypesData ?? []}
                            help_id="category"
                            value={fileType}
                            handleSelectedOption={handleFileType}
                            validation={formErrors.fileType}/>

                        <LoadingState
                            show_loading={loadingLists}
                            show_error={!loadingLists && !data_lists.fileTypesData}
                            allow_reload={true}
                            reload_callback={reloadLists}
                        />

                        <Line/>
                        {
                            fileType.label === 'Document' && (
                                <>
                                    <TextInput
                                        name='doi'
                                        title='Digital Object Identifier (DOI)'
                                        description='The DOI of the document'
                                        placeholder='Provide DOI for the document if available'
                                        handleInputChange={(event) => {
                                            event.preventDefault();
                                            setDoi(event.target.value)
                                        }}
                                    ></TextInput>
                                    <Line/>
                                </>
                            )
                        }

                        {
                            fileTypeCategoriesList.length>0 &&  (
                                <>
                                    <CheckBoxGroup
                                        title="Type"
                                        required={true}
                                        description="You can select more than one"
                                        //help_id="topics"
                                        name="fileTypeSubcategories"
                                        options={fileTypeCategoriesList??[]}
                                        handleSelectedOptions={handleFileTypeCategories}
                                        values={selectedFileTypeCategories}
                                        validation={formErrors.fileTypeCategories}/>
                                    <Line />
                                </>
                            )
                        }

                        <TextArea
                            title="Keywords"
                            required={true}
                            description="For more than one keywords use comma (,) to separate."
                            rows={4}
                            help_id="keywords"
                            name="keywords"
                            placeholder="Keywords"
                            handleInputChange={handleKeywords}
                            value={keywords}
                            validation={formErrors.keywords}/>
                        <Line/>

                        <CheckBoxGroup
                            title="Topics"
                            required={true}
                            description="You can select more than one topic."
                            help_id="topics"
                            name="topics"
                            options={data_lists.topicData ?? []}
                            handleSelectedOptions={handleTopics}
                            values={selectedTopics}
                            validation={formErrors.topics}/>

                        <LoadingState
                            show_loading={loadingLists}
                            show_error={!loadingLists && !data_lists.topicData}
                            allow_reload={true}
                            reload_callback={reloadLists}
                        />


                        {subtopicData != undefined && subtopicData.length > 0 ? (
                            <>
                                <Line/>
                                <CheckBoxGroup
                                    title="Subtopics"
                                    required={true}
                                    description="Choose the relevant subtopics on the topics you picked:"
                                    name="subtopics"
                                    options={subtopicData ?? []}
                                    values={selectedSubtopics}
                                    handleSelectedOptions={handleSubtopics}
                                    validation={formErrors.subtopics}/>
                            </>
                        ) : (
                            <></>
                        )}
                        <Line/>
                        <Creator
                            title="Creator(s)"
                            description='Type the name of the creator and press "Add Creator".'
                            name="creators"
                            creators={creators}
                            help_id="creators"
                            buttonTitle="Add creator"
                            isRequired={true}
                            handleStateCreators={handleCreators}
                            validation={formErrors.creators}
                        />
                        <Line/>
                        <RadioGroup
                            title="License"
                            required={true}
                            name="license"
                            options={data_lists.licenseData ?? []}
                            value={license}
                            handleSelectedOption={handleLicense}
                            help_id='license'
                            validation={formErrors.license}/>

                        <LoadingState
                            show_loading={loadingLists}
                            show_error={!loadingLists && !data_lists.licenseData}
                            allow_reload={true}
                            reload_callback={reloadLists}
                        />
                        <Line/>
                        <CheckBoxGroup
                            title='Intended purpose'
                            required={true}
                            name='purpose'
                            options={data_lists.purposeData ?? []}
                            values={purpose}
                            handleSelectedOptions={handlePurpose}
                            validation={formErrors.purpose}
                            help_id="purpose"
                        />
                      <LoadingState
                          show_loading={loadingLists}
                          show_error={!loadingLists && !data_lists.purposeData}
                          allow_reload={true}
                          reload_callback={reloadLists}
                      />
                        <Line/>
                        <DateSelection
                            title='Date of completion'
                            required={true}
                            handleChange={handleDate}
                            name='select-date'
                            value={date}
                            description="The date of the knowledge object's completion"
                            validation={formErrors.date}/>
                        <Line/>
                        {data_lists.locationData != undefined && data_lists.locationData.length > 0 ?
                            <>
                                <SelectDropdown
                                    title='Geographic location(s)'
                                    required={true}
                                    help_id="locations"
                                    description="Select the location(s) related to the knowledge object's content"
                                    name="locations"
                                    placeholder="Start typing country names to search for locations"
                                    options={data_lists.locationData.map((location) => {
                                        return {
                                            value: location.value,
                                            label: location.label
                                        }
                                    })}
                                    values={locations}
                                    handleSelected={handleLocations}
                                    validation={formErrors.locations}/>
                            </>
                            : ''}

                    </FieldBox>
                    <ControlSection
                        //saveText='Save draft'
                        controlText='Submit'
                        errors={formErrors}
                        errorMessage='Please fill in all required fields'
                        onClickSave={() => {
                        }}
                        onClickControl={() => {
                        }}
                        icon={faArrowRight}/>
                </Form>
            </FormWrapper>

        </>
    );
};